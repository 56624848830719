
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';
import QuestionSelect from '@/components/QuestionSelect.vue';
import PlainContent from '../types/PlainContent';
import QuizContent from '@/content/QuizContent';

export default defineComponent({
  name: 'QuizContainer',
  components: {
    QuestionSelect,
  },
  setup() {
    const content: PlainContent = QuizContent;
    const router = useRouter();
    const question = {
      id: 'quizQuestion',
      options: [
        { responseIndex: '' },
        { responseIndex: '1,000' },
        { responseIndex: '2,000' },
        { responseIndex: '5,000' },
        { responseIndex: '10,000' },
        { responseIndex: 'Over 25,000' },
      ],
    };
    const submitQuiz = () => {
      router.push({ name: 'QuizResults' });
    };
    return {
      question,
      submitQuiz,
      content,
    };
  },
});
