/* eslint-disable global-require */
/* eslint-disable max-len */
import PlainContent from '@/types/PlainContent';

const QuizContent: PlainContent = {
  title: 'Do you know...?',
  body: `<p>
            Hinkley Point C is revolutionary, and not just because it’s the first new nuclear power station to be built in the UK in over 20 years.
        </p>
        <p>
          We’re committed to making an impact by increasing local employment, investing in new training facilities, and giving back to the community.
        </p>
        <h3>How many opportunities do we aim to create during the construction and operation of Hinkley Point C?</h3>`,
};

export default QuizContent;
