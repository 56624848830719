
import { defineComponent } from 'vue';
import { mapState, mapGetters } from 'vuex';
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import QuizContainer from '@/components/QuizContainer.vue';
import {
  OutcomeCriteria,
  outcomeSuitability,
  OutcomeScore,
} from '@/lib/totaliser';
import store from '@/store';

export default defineComponent({
  name: 'Quiz',
  components: {
    Header,
    Footer,
    QuizContainer,
  },
  setup() {
    const questions = store.getters.getQuestions;
    return { questions };
  },
  data() {
    return {
      answer: '',
    };
  },
  computed: {
    ...mapState(['responses']),
    ...mapGetters(['getQuestions']),
  },
  methods: {
    getResult(score: number) {
      const answer = (score === 1 ? 'Floor Technician' : 'Ladder Operator');
      return answer;
    },
    getAnswer() {
      const outcome1Criteria: { [Key: string]: OutcomeCriteria } = {
        q1: { id: 'q1', operator: 'lte', responseIndex: 3 },
      };
      const userResponses: any[] = store.state.responses;

      const result: OutcomeScore = outcomeSuitability(
        outcome1Criteria,
        userResponses,
      );
      this.answer = this.getResult(result.score);
    },
  },
});
