export interface Question {
  question_text: string;
  id: string;
  options: string[] | number[];
}

export interface QuestionResponse {
  id: string;
  responseIndex: number | null;
}

export interface OutcomeCriteria {
  id: string;
  responseIndex: number;
  operator: string;
}

export interface OutcomeScore {
  max: number;
  score: number;
}

export interface OutcomeSuitability {
  id: string;
  score: OutcomeScore;
}

export interface Outcome {
  criteria: { [Key: string]: OutcomeCriteria };
  id: string;
}

export const operators = [
  { name: '<=', value: 'lte' },
  { name: 'equals', value: 'eq' },
  { name: '=>', value: 'gte' },
  { name: 'N/A', value: 'skip' },
];

const eq = (criteria: OutcomeCriteria, responseIndex: number): number => {
  const match = responseIndex == criteria.responseIndex;
  return opfunc(match);
};

const gte = (criteria: OutcomeCriteria, responseIndex: number): number => {
  const match = responseIndex >= criteria.responseIndex;
  return opfunc(match);
};

const lte = (criteria: OutcomeCriteria, responseIndex: number): number => {
  const match = responseIndex <= criteria.responseIndex;
  return opfunc(match);
};

const opfunc = (match: boolean): number => {
  let penalty = -1;
  if (match) {
    penalty = 0;
  }
  return penalty;
};

export const outcomeSuitability = (
  criteria: { [Key: string]: OutcomeCriteria },
  responses: QuestionResponse[],
): OutcomeScore => {

  let suitabilityScore = Object.keys(criteria).length;

  for (const key in criteria) {

    const c: OutcomeCriteria = criteria[key];

    responses.forEach((r) => {
      if (r.id == c.id) {
        if (c.operator === 'eq' && r.responseIndex !== null) {
          suitabilityScore += eq(c, r.responseIndex);
        }

        if (c.operator === 'gte' && r.responseIndex !== null) {
          suitabilityScore += gte(c, r.responseIndex);
        }

        if (c.operator === 'lte' && r.responseIndex !== null) {
          suitabilityScore += lte(c, r.responseIndex);
        }

        if (c.operator === 'skip') {
          suitabilityScore += 0; // No penalty if the option is skipped
        }
      }
    });
  }

  return { score: suitabilityScore, max: Object.keys(criteria).length };
};

export const multipleOutcomes = (
  outcomes: Outcome[],
  responses: QuestionResponse[],
): OutcomeSuitability[] => {
  const outcomeScores: OutcomeSuitability[] = [];
  outcomes.forEach((outcome) => {
    const result: OutcomeScore = outcomeSuitability(
      outcome.criteria,
      responses,
    );
    const suitability: OutcomeSuitability = {
      id: outcome.id,
      score: result,
    };
    outcomeScores.push(suitability);
  });
  return outcomeScores;
};

export const orderMultipleOutcomesByScore = (outcomes: OutcomeSuitability[]): any[] => {
  const orderedOutcomes = outcomes.sort(function(a, b) {
    return (b.score.score - a.score.score);
  });
  return orderedOutcomes;
};